import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/main.css";
import "./css/responsive.css";
import Navbar from "./common/Navbar";
import Firstfold from "./components/Firstfold";
import Secondfold from "./components/Secondfold";
import Thirdfold from "./components/Thirdfold";
import Forthfold from "./components/Forthfold";
import Fifthfold from "./components/Fifthfold";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Navbar />

      <Firstfold />
      <Secondfold />
      <Thirdfold />
      <Forthfold />
      <Fifthfold />
      <Footer url="https://bfmslabs.com" />
    </>
  );
}

export default App;
