import React from "react";

export default function Thirdfold() {
  return (
    <>
      <div className="services" id="services">
        <div className="container">
          <div className="content">
            <div className="small">Services</div>
            <h3>
              We offer a wide range of services
              <br /> and provide complete client satisfaction
            </h3>
          </div>

          <div className="services-section">
            <div className="row">
              <div
                className="about-item-two col-md-6 col-lg-3 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="box">
                  <div className="service-box">
                    <i className="serviceicon"></i>
                  </div>
                  <div className="heading">UX Design</div>
                  <p>
                    Great online experiences happen only when a well planned UX
                    strategy is visualized with top class design aesthetics; and
                    we do it best.
                  </p>
                </div>
              </div>

              <div
                className="about-item-two col-md-6 col-lg-3 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="box">
                  <div className="service-box" id="development">
                    <i className="serviceicon"></i>
                  </div>
                  <div className="heading">Web Development</div>
                  <p>
                    Technological expertise coupled with award-winning UX skills
                    is what makes us the leading web development company in
                    India - and helps us build world class websites and web
                    applications.
                  </p>
                </div>
              </div>

              <div
                className="about-item-two col-md-6 col-lg-3 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="box">
                  <div className="service-box" id="marekting">
                    <i className="serviceicon"></i>
                  </div>
                  <div className="heading">Digital Marketing</div>
                  <p>
                    We're a Google Partner and we offer unique digital marketing
                    solutions that’d load your inbox with inquiries.
                  </p>
                </div>
              </div>

              <div
                className="about-item-two col-md-6 col-lg-3 wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="box">
                  <div className="service-box" id="branding">
                    <i className="serviceicon"></i>
                  </div>
                  <div className="heading">Branding</div>
                  <p>
                    Our highly creative team of designers always excels in
                    creating unique brand identity and out-of-the-box designs
                    that speaks on its own!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
