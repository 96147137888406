import React from "react";
import logo from "../images/logo.png";

export default function Footer(props) {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <ul className="foot-top-left">
                <li>
                  <a href={props.url} className="foot-logo">
                    <img src={logo} alt="Footer Logo" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-3">
              <div className="textwidget custom-html-widget">
                <ul>
                  <li className="foot-list-bold">Reach us</li>
                  <li className="foot-text">
                    <i className="fa fa-location-arrow" aria-hidden="true"></i>
                    <a
                      href="https://g.page/bfmlabs?share"
                      target="_blank"
                      rel="noreferrer"
                      style={{ lineHeight: "25px" }}
                    >
                      Nexa Tower, Office 304, Third-floor, Phase 8B, Industrial
                      Area, Sahibzada Ajit Singh Nagar, Punjab 160065
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-3">
              <ul>
                <li className="foot-list-bold">Connect</li>
                <li className="foot-text">
                  <a href="mailto:info@bfmslabs.com">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    info@bfmslabs.com
                  </a>
                </li>
                <li className="foot-text">
                  <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                  <a href="tel:+91-8559085298">+91-8559085298</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <ul>
                <li className="foot-list-bold">Follow us</li>
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/BFM-LABS-121709269225727/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/bfm-labs-379952194/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="live:.cid.b71534a9a6c48b16"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-skype"></i>
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>

        <div className="foot-info col-lg-12 text-center">
          <i className="fa fa-copyright"></i> 2023 BFMS labs. All Rights
          Reserved.
        </div>
      </footer>
    </>
  );
}
