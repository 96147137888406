import React, { useState } from "react";

import atlast from "../images/atlast-logo.png";
import atlastportfoilio from "../images/atlast-portfoilio.png";
import goidex from "../images/goidex.png";
import hosttransformlogo from "../images/host-transform-logo.png";
import host2transform from "../images/host2transform.png";
import goidexx from "../images/go-idex.png";
import spprowsports from "../images/spprow-sports.png";
import spprowsports1 from "../images/spprowsports.png";
import leaveragelogo from "../images/leaverage-logo.png";
import leaverageportfolio from "../images/leaverage-portfolio.png";
import kweenlogo from "../images/kween-logo.png";
import kweenportfolio from "../images/kweens-portfolio.png";
import iorganiclogo from "../images/iorganic-logo.png";
import iorganicportfolio from "../images/iorganic-portfolio.png";
import beaconlogo from "../images/beacon-logo.png";
import beaconportfolio from "../images/beacon-portfolio.png";
import soccerlogo from "../images/soccer-logo.png";
import soccerportfolio from "../images/soccer-portfolio.png";
import academylogo from "../images/academy-logo.png";
import academyportfolio from "../images/academy-portfolio.png";
import whitetigerlogo from "../images/whitetiger-logo.png";
import whitetigerportfolio from "../images/whitetiger-portfolio.png";
import blockbloxlogo from "../images/blockblox-logo.png";
import blockbloxportfolio from "../images/blockblox-portfolio.png";
import draphroditelogo from "../images/draphrodite-logo.png";
import draphroditeportfolio from "../images/draphrodite-portfolio.png";

import designn from "../images/designn.png";
import html from "../images/html.png";
import wordpress from "../images/wordpress.png";
import jquery from "../images/jquery.png";
import elementor from "../images/elementor.png";
import woocommerce from "../images/woocommerce.png";
import learndash from "../images/learndash.png";
import wpbakery from "../images/wpbakery.png";
import squarespace from "../images/squarespace.png";

const information = [
  {
    description:
      "Atlast gives, secure professional development opportunities that fit your professional and family needs.",
    imglogo: atlast,
    altlogo: "Atlast",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logojquery: jquery,
    altjquery: "jQuery",
    imgportfolio: atlastportfoilio,
    altportfolio: "Atlast Portfolio",
  },
  {
    description:
      "GOIDEX is to create the kind of travel in the world that has a high value of money for the traveller, makes the world a better place and gives you (and us) the satisfaction of having contributed to making the world a better place.",
    imglogo: goidex,
    altlogo: "GOIDEX",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logojquery: jquery,
    altjquery: "jQuery",
    logoelementor: elementor,
    altelementor: "Elementor",
    imgportfolio: goidexx,
    altportfolio: "Goidex Portfolio",
  },
  {
    description:
      "Host2transform provide upskill forward-thinking leaders, change consultants, and change agencies to help fast-changing organisations transform,      thrive, and lead in our complex and digital world.",
    imglogo: hosttransformlogo,
    altlogo: "Host2transform",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logojquery: jquery,
    altjquery: "jQuery",
    logowoccommerce: woocommerce,
    altwocommerce: "WooCommerce",
    imgportfolio: host2transform,
    altportfolio: "Host2transform Portfolio",
  },
  {
    description:
      "Sparrowsports professionally run Kids birthday parties and entertainment programs to suit all needs in Sydney and Brisbane.",
    imglogo: spprowsports,
    altlogo: "Sparrowsports",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logoelementor: elementor,
    altelementor: "Elementor",
    imgportfolio: spprowsports1,
    altportfolio: "Sparrowsports Portfolio",
  },
  {
    description:
      "Leveragedbreakdowns conquering Real Estate Private Equity requires more than rote Excel practice and interview prep. Our team of REPE insiders delivers the knowledge you need to conquer your job search and excel on the desk once you've landed your dream job.",
    imglogo: leaveragelogo,
    altlogo: "Leveraged Breakdowns",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logoelementor: elementor,
    altelementor: "Elementor",
    logolearndash: learndash,
    altlearndash: "Learndash",
    logojquery: jquery,
    altjquery: "jQuery",
    imgportfolio: leaverageportfolio,
    altportfolio: "Leveraged Breakdowns Portfolio",
  },
  {
    description:
      "Founded by Queen Emmanuel in 2000, Kween's Parlour is renowned for unbeatable service and amazing results always. We guarantee quality in all of our services, and are driven by our long withstanding commitment to our customers’ needs. Stop by today and get the personalized attention you deserve!",
    imglogo: kweenlogo,
    altlogo: "Kween's Parlour",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logobakery: wpbakery,
    altbakery: "WP Bakery",
    logojquery: jquery,
    altjquery: "jQuery",
    imgportfolio: kweenportfolio,
    altportfolio: "Kween's Parlour Portfolio",
  },
  {
    description:
      "iOrganic cow milk is naturally produced without antibiotics, synthetic growth hormones and toxic pesticides. It has a rich flavour, creamy texture and that beautiful aroma, many of you have forgotten due to dependence on poor quality packet milk. 'It tastes like milk should taste' We know you will agree!",
    imglogo: iorganiclogo,
    altlogo: "iOrganic",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logosquarespace: squarespace,
    altsquarespace: "Squarespace",
    imgportfolio: iorganicportfolio,
    altportfolio: "iOrganic Portfolio",
  },
  {
    description:
      "Beacon employs cross-functional teams to execute data transformation projects and operationalize AI / ML models. Whether via ad-hoc projects or team augmentation, our strategic approach delivers efficient, measurable results.",
    imglogo: beaconlogo,
    altlogo: "Beacon",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logosquarespace: squarespace,
    altsquarespace: "Squarespace",
    imgportfolio: beaconportfolio,
    altportfolio: "Beacon Portfolio",
  },
  {
    description:
      "SC PRO SOCCER BRINGS A WEALTH OF KNOWLEDGE BOTH IN PRO FOOTBALL AND YEARS OF MENTORING PLAYERS REACH THEIR TRUE POTENTIAL. WE ARE HERE EVERY STEP OF THE WAY TO HELP YOU ACHIEVE YOUR DREAMS.",
    imglogo: soccerlogo,
    altlogo: "SC PRO SOCCER",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logosquarespace: squarespace,
    altsquarespace: "Squarespace",
    imgportfolio: soccerportfolio,
    altportfolio: "SC PRO SOCCER Portfolio",
  },
  {
    description:
      "Safe Zone Academy offers online training resources to get you and your team an overview of the Safe Zone System, Dashboard and Safe Zone App. In addition, there are Training Paths and exams providing the tools and knowledge needed to become Safe Zone Certified.",
    imglogo: academylogo,
    altlogo: "Safe Zone Academy",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logoelementor: elementor,
    altelementor: "Elementor",
    logowoccommerce: woocommerce,
    altwocommerce: "WooCommerce",
    imgportfolio: academyportfolio,
    altportfolio: "Safe Zone Academy Portfolio",
  },
  {
    description:
      "Qigong is a system of knowledge that comprises at least 5,000 years of human evolution and covers all the fields of human interest. It is officially known in China as the science of human potential and development.",
    imglogo: whitetigerlogo,
    altlogo: "Qigong",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logoelementor: elementor,
    altelementor: "Elementor",
    logowoccommerce: woocommerce,
    altwocommerce: "WooCommerce",
    imgportfolio: whitetigerportfolio,
    altportfolio: "Qigong Portfolio",
  },
  {
    description:
      "Kitsune Music Academy is a modern way to learn music, and we consider it to be either a supplement or replacement for traditional music lessons. We encourage our students to approach a variety of topics at their own pace. You are not limited in the breadth of courses available to you with a KMA account – you have full access to every topic; be it Theory, Production, Bass, as well as all topics to come (including Piano, Drums, and more)!",
    imglogo: blockbloxlogo,
    altlogo: "Block Blox",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logowoccommerce: woocommerce,
    altwocommerce: "WooCommerce",
    imgportfolio: blockbloxportfolio,
    altportfolio: "Block Blox Portfolio",
  },
  {
    description:
      "Do you long for a career that you feel passionate about, where you can be your own boss, have the freedom to design your own path, and work your own hours?",
    imglogo: draphroditelogo,
    altlogo: "Draphrodite",
    logodesignn: designn,
    altdesignn: "Design",
    logoHTML: html,
    althtml: "HTML",
    logojquery: jquery,
    altjquery: "jQuery",
    logowordpress: wordpress,
    altwordpress: "WordPress",
    logolearndash: learndash,
    altlearndash: "Learndash",
    logobakery: wpbakery,
    altbakery: "WP Bakery",
    imgportfolio: draphroditeportfolio,
    altportfolio: "Draphrodite Portfolio",
  },
];

// Carousel(props) Class
// Sets state = to the first array index and changes based on addState(increment, lastSlide) function
const Carousel = (props) => {
  const [count, setCount] = useState(0);

  const addState = (increment, lastSlide) => {
    let currentSlide = count;
    if (currentSlide === lastSlide && increment === 1) {
      setCount(0);
    } else if (currentSlide === lastSlide && increment === -1) {
      setCount(count + increment);
    } else if (currentSlide === 0 && increment === -1) {
      setCount(lastSlide);
    } else if (currentSlide < lastSlide) {
      setCount(parseInt(count) + parseInt(increment));
    }
  };

  //setInterval(function () {}, 5000);
  const path = information;
  const index = count;
  return (
    <div className="Slider-wrapper">
      <button
        className="Slider-prev"
        onClick={() => addState(-1, path.length - 1)}
      >
        &#10094;
      </button>
      <div className="Slide-wrapper">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="website-logo idex">
              <img src={path[index].imglogo} alt={path[index].altlogo} />
            </div>
            <div className="website-content">
              <p>{path[index].description}</p>
            </div>

            <div className="tech">
              <ul>
                <li>
                  <img
                    src={path[index].logodesignn}
                    alt={path[index].altdesignn}
                  />
                </li>
                <li>
                  <img src={path[index].logoHTML} alt={path[index].althtml} />
                </li>
                <li>
                  <img
                    src={path[index].logowordpress}
                    alt={path[index].altwordpress}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logojquery}
                    alt={path[index].altjquery}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logoelementor}
                    alt={path[index].altelementor}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logowoccommerce}
                    alt={path[index].altwocommerce}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logolearndash}
                    alt={path[index].altlearndash}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logobakery}
                    alt={path[index].altbakery}
                  />
                </li>
                <li>
                  <img
                    src={path[index].logosquarespace}
                    alt={path[index].altsquarespace}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="portfilio-img">
              <img
                src={path[index].imgportfolio}
                alt={path[index].altportfolio}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="Slider-next"
        id="nextbtnslider"
        onClick={() => addState(1, path.length - 1)}
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
