import React from "react";
import smallLogo from "../images/banner-img.png";

export default function Firstfold() {
  return (
    <>
      <div className="header" id="home">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="intro">
                <h1>
                  Transform Your Business With The Right Technology Partner
                </h1>
                <p>
                  Our Winning Strategy Acquires, Delight And Retain Your
                  Potential Clients.
                </p>
                <a className="button" href="#footer">
                  Contact US{" "}
                  <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-sm-2"></div>

            <div className="col-sm-5">
              <div className="blue-box">
                <img src={smallLogo} alt="Small Logo" />
                <div className="ocean">
                  <div className="wave"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
