import React from "react";
import logo from "../images/logo.png";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
        <div className="container">
          <a className="navbar-brand" href="https://www.bfmslabs.com/">
            <img src={logo} alt="logo" />
          </a>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <a className="nav-link page-scroll" href="#home">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link page-scroll" href="#about">
                  About us
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link page-scroll" href="#services">
                  Services{" "}
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link page-scroll" href="#our-wrk">
                  Our Work
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link page-scroll" href="#footer">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
