import React from "react";

export default function Fifthfold() {
  return (
    <>
      <div className="process">
        <div className="container">
          <div className="content">
            <div className="small">Workflow</div>
            <h3>
              This is how we streameline <br />
              our design workflow
            </h3>
          </div>

          <div className="services-section">
            <div className="row">
              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                <div className="card-box">
                  <div className="number">1</div>
                  <div className="service-box" id="discuss">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Discuss</div>
                </div>
              </div>

              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="card-box">
                  <div className="number" id="two">
                    2
                  </div>
                  <div className="service-box" id="planing">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Planing</div>
                </div>
              </div>

              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <div className="card-box">
                  <div className="number" id="three">
                    3
                  </div>
                  <div className="service-box" id="skecth">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Sketch</div>
                </div>
              </div>

              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="card-box">
                  <div className="number" id="four">
                    4
                  </div>
                  <div className="service-box" id="design">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Design</div>
                </div>
              </div>

              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div className="card-box">
                  <div className="number" id="five">
                    5
                  </div>
                  <div className="service-box" id="devlp">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Development</div>
                </div>
              </div>

              <div
                className="col-sm-2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".6s"
              >
                <div className="card-box">
                  <div className="number" id="six">
                    6
                  </div>
                  <div className="service-box" id="deliver">
                    <i className="serviceicons"></i>
                  </div>
                  <div className="heading">Deliver</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cta">
        <div className="container">
          <div
            className="conact-box wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div className="col-sm-5">
              <h1>Let's talk about what we can build together</h1>
            </div>

            <div className="col-sm-2"></div>

            <div className="col-sm-5">
              <p>
                Whatever may be your requirement - be it a simple website
                design, a complex data driven web application development, an
                ecommerce website, a native or cross platform mobile app
                development, a logo and brand identity design - we have a
                solution for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
