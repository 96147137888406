import React from "react";
import aboutimg from "../images/about-us.jpg";

export default function Secondfold() {
  return (
    <>
      <div className="about-us" id="about">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-6 wow  fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="about-img">
                <img src={aboutimg} alt="About" />
              </div>
            </div>

            <div
              className="col-sm-6 wow  fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="content">
                <div className="small">GET TO KNOW US</div>
                <h3>
                  Work with tried and true development and digital marketing
                  experts
                </h3>

                <p>
                  BFMS labs has an experienced and fanatical team of designers
                  and developers. We work ingeniously and ardently to face and
                  win every challenge we come across.{" "}
                </p>

                <p>
                  We don’t only take your order and serve you the required
                  services. Instead, we take time to study your business goals
                  and objectives to build a strategic plan to design your
                  website.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
