import React from "react";
import Slider from "../common/Slider";

export default function Forthfold() {
  return (
    <>
      <div className="portfoilio" id="our-wrk">
        <div className="container">
          <div className="content">
            <div className="small">Portfolio</div>
            <h3>Project's we have accomplished-</h3>
          </div>
          <Slider />
        </div>
      </div>
    </>
  );
}
